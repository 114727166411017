<template>
  <div v-if="controls===true">
    <div>Live: {{ isActive }}</div>
    <div>
      <button @click="pause">
        pause
      </button>
      <button @click="resume">
        resume
      </button>
    </div>
  </div>
</template>

<script setup>
    import { ref,toRefs } from 'vue'
    import { useTimeoutPoll } from '@vueuse/core'
    const props = defineProps({ src: String, scanrate: Number, controls: Boolean });
    const { src, scanrate } = toRefs(props);
    const emit = defineEmits(['on-data']);
    async function fetchData() {
      const url = src.value;
      if(url !== '') {
        fetch(url, {
          headers: { 'Content-type': 'application/json' },
        }).then((res) => res.json()).then((response) => {
          emit('on-data',response);
        }).catch((error) => {
          emit('on-data',-1);
        });
      } else {
        emit('on-data',-2);
      }
    }
    const { isActive, pause, resume } = useTimeoutPoll(fetchData, scanrate.value, {immediate:true});

</script>
