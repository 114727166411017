<template>
    <div class="main_v8">
        <h1>AutoBuilder Status</h1>
        <livedata
          :src="url"
          :scanrate="scanrate"
          :controls="controls"
          @on-data="updatedata">
        </livedata>
        <livedata2
          :src="url2"
          :scanrate="scanrate2"
          :controls="controls"
          @on-data="updatedata2">
        </livedata2>
        <livedatainternal
          :scanrate="scanrateinternal"
          :controls="controls"
          @on-data="updatedatainternal">
        </livedatainternal>
        <div class="boxes">
          <div class="tiles">
            <div>Next Checkpoint</div>
            <div class="countdown">{{nexttime}} min</div><br><br>
            <div>Current Builds In Process</div><br>
              <div class="boxes" v-if="activebuilds">
                <div v-for="build in builds" class="buildtile">
                  project
                  <div class="builds">{{ splitter(build.commit,1) }}</div>
                  elapsetime
                  <div class="builds">{{build.elapsetime}}</div>
                  commit/build<div class="builds">{{splitter(build.commit,0)}}</div>
                </div>
              </div>
              <div class="builds" v-if="!activebuilds">No Active Builds at this Time...</div>
          </div>
          <div class="tiles">
            <div>Build History</div>
            <div class="innertiles">
              <table id="builds">
                <tr>
                  <th>Build Date Time</th>
                  <th>Profile</th>
                  <th>Commit</th>
                </tr>
                <template v-for="item in buildhistory">
                  <tr>
                    <td>{{ item.builddate }}</td>
                    <td>{{ item.buildname }}</td>
                    <td>{{ item.buildnumber }}</td>
                  </tr>
                </template>
              </table>
            </div>
            
          </div>
        </div>
    </div>
  </template>
  
  <script setup>
    import { ref } from 'vue';
    import livedata from '../components/livedata/data.vue';
    import livedata2 from '../components/livedata/data.vue';
    import livedatainternal from '../components/livedata/datainternal.vue';

    const url = ref("https://build.wave7.cloud/api/getactivebuilds.php");
    const url2 = ref("https://build.wave7.cloud/api/getbuildhistory.php");
    const nexttime = ref("---");
    const activebuilds = ref(false);
    const builds = ref([]);
    const buildhistory = ref([]);
  
    const scanrate = ref(5000);
    const scanrate2 = ref(5000);
    const scanrateinternal = ref(1000);
    const controls = ref(false);
  
    function updatedata(val) {
      builds.value = val;
      if(val.length>0)
      {
        activebuilds.value = true;
      }
      else
      {
        activebuilds.value = false;
      }
    }

    function updatedata2(val) {
        buildhistory.value = sortByProperty(val,"attributes.builddate");
    }

    function splitter(value,index) {
      const sp = value.split(",");
      return sp[index];
    }

    function updatedatainternal() {
      nexttime.value = nextbuild();
    }
  
    function nextbuild() {
      var d = new Date();
      var seconds = d.getMinutes() * 60 + d.getSeconds(); //convet 00:00 to seconds for easier caculation
      var fiveMin = 60 * 5; //five minutes is 300 seconds!
      var timeleft = fiveMin - seconds % fiveMin; // let's say 01:30, then current seconds is 90, 90%300 = 90, then 300-90 = 210. That's the time left!
      var result = String(parseInt(timeleft / 60)).padStart(2,'0') + ':' + String(timeleft % 60).padStart(2,'0'); //formart seconds into 00:00 
      return result;
    }


    function sortByProperty(objArray, prop){
        if (arguments.length<2) throw new Error("ARRAY, AND OBJECT PROPERTY MINIMUM ARGUMENTS, OPTIONAL DIRECTION");
        if (!Array.isArray(objArray)) throw new Error("FIRST ARGUMENT NOT AN ARRAY");
        const clone = objArray.slice(0);
        const direct = arguments.length>2 ? arguments[2] : -1; //Default to ascending
        const propPath = (prop.constructor===Array) ? prop : prop.split(".");
        clone.sort(function(a,b){
            for (let p in propPath){
                    if (a[propPath[p]] && b[propPath[p]]){
                        a = a[propPath[p]];
                        b = b[propPath[p]];
                    }
            }
            // convert numeric strings to integers
            a = a.match(/^\d+$/) ? +a : a;
            b = b.match(/^\d+$/) ? +b : b;
            return ( (a < b) ? -1*direct : ((a > b) ? 1*direct : 0) );
        });
        return clone;
    }
  </script>
  
  <style lang="scss" scoped>
    @import url('https://fonts.cdnfonts.com/css/lt-binary-neue');
    .main{
        padding-top: 70px;
    }
    .countdown{
        font-size:480%;
        font-weight: bold;
        font-family: 'LT Binary Neue', sans-serif;
        // font-family: 'LT Binary Neue Round', sans-serif;         
    }
    .builds{
        font-size:24px;
        font-family: 'LT Binary Neue', sans-serif;
        // font-family: 'LT Binary Neue Round', sans-serif;         
    }
    .tiles {
      border: 1px solid #aaa;
      padding:20px;
      border-radius: 10px;
      width:47%;
    }
    .innertiles {
      border: 1px solid #aaa;
      padding:20px;
      border-radius: 10px;
      overflow-y: auto;
      height:800px;
    }
    .buildtile{
        border: 2px solid black;
        border-radius:10px;
        padding:10px;
        width:300px;
        background-color: azure;
        margin:10px;
    }
    .buildtile:nth-child(even){background-color: #353837;color:white;}
    
    .boxes {
      box-sizing: border-box;
      display: flex;
      padding:10px;
      flex-direction: row;
      align-items: left;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    #builds {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    #builds td, #customers th {
      border: 1px solid #ddd;
      padding: 8px;
    }

    #builds tr:nth-child(even){background-color: #f2f2f2;}

    #builds tr:hover {background-color: #ddd;}

    #builds th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #9aa4a0;
      color: white;
    }
  </style>
  